.scrollable {
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden;
}

.scrollable::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scrollable::-webkit-scrollbar:vertical {
  width: 8px;
}

.scrollable::-webkit-scrollbar:horizontal {
  height: 8px;
}

.scrollable::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: transparent;
  transition: all 0.2s ease-in;
}

.scrollable:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease-in;
}

.scrollable::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}
